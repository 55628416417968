<template>
  <b-overlay :show="loading">
    <div class="">
      <div class="d-flex">
        <b-link class="text-dark" :to="{ name: 'statistik' }">
          <feather-icon icon="ArrowLeftIcon" class="mr-50" />
        </b-link>
        <strong class="mb-1">{{ paketDetail.name }} </strong>
      </div>
      <b-card>
        <v-select
          id="sekolah"
          v-model="jadwal"
          :options="listJadwal"
          label="name"
          placeholder="Pilih jadwal yang sudah kamu selesaikan"
        />
      </b-card>
      <section id="result" v-if="jadwal">
        <div class="mt-2">
          <b-card no-body>
            <b-card-body>
              <chartjs-component-bar-chart
                :height="400"
                :data="chartjsDataBar.data"
                :options="chartjsDataBar.options"
              />
            </b-card-body>
          </b-card>
        </div>
        <div class="text-center">
          <ul class="d-flex">
            <li style="color: #e83b04">Benar</li>
            <li style="color: #b9483d" class="ml-4">Salah</li>
            <li style="color: #d09792" class="ml-4">Kosong</li>
          </ul>
        </div>
        <section>
          <div class="">
            <b-row>
              <b-col lg="4" sm="6" v-for="(item, i) in dataPersentase" :key="i">
                <b-card>
                  <h5>
                    <b> {{ item.ujian_name }}</b>
                  </h5>
                  <b-row>
                    <b-col md="6">
                      <ul style="margin-left: 20px; padding: 0">
                        <span>
                          <li style="color: #e83b04">
                            {{ item.jumlah_benar }}%
                          </li>
                          <li style="color: #b9483d">
                            {{ item.jumlah_salah }}%
                          </li>
                          <li style="color: #d09792">
                            {{ item.jumlah_kosong }}%
                          </li>
                        </span>
                      </ul>
                    </b-col>
                    <b-col md="6">
                      <chartjs-component-doughnut-chart
                        :height="100"
                        :data="
                          dataDoughnut(
                            item.jumlah_benar,
                            item.jumlah_salah,
                            item.jumlah_kosong
                          )
                        "
                        :options="chartjsDataDoughnut.options"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </section>
      </section>
      <section v-else>
        <b-alert show class="p-1" variant="warning">
          <span class="text-dark d-block text-center"
            >Harap pilih jadwal untuk melihat hasil statistik tryout anda.</span
          >
        </b-alert>
      </section>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BAlert,
  BLink,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BRow,
  BCol,
} from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import ChartjsComponentBarChart from "./chart/ChartjsComponentBarChart.vue";
import ChartjsComponentDoughnutChart from "./chart/ChartjsComponentDoughnutChart.vue";
import { $themeColors } from "@themeConfig";
import vSelect from "vue-select";
const chartColors = {
  benarColor: "#e83b04",
  salahColor: "#b9483d",
  kosongColor: "#d09792",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
};
export default {
  data() {
    return {
      loading: false,
      dataGrafik: {},
      dataPersentase: [],
      listJadwal: [],
      jadwal: null,
      chartjsDataDoughnut: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          cutoutPercentage: 60,
          legend: { display: false },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const label = data.datasets[0].labels[tooltipItem.index] || "";
                const value = data.datasets[0].data[tooltipItem.index];
                const output = ` ${label} : ${value} %`;
                return output;
              },
            },
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
        },
        data: {
          datasets: [
            {
              labels: ["Benar", "Salah", "Kosong"],
              data: [80, 20, 0],
              backgroundColor: [
                chartColors.benarColor,
                chartColors.salahColor,
                chartColors.kosongColor,
              ],
              borderWidth: 0,
              pointStyle: "rectRounded",
            },
          ],
        },
        dataindo: {
          datasets: [
            {
              labels: ["Benar", "Salah", "Kosong"],
              data: [100, 0, 0],
              backgroundColor: [
                chartColors.benarColor,
                chartColors.salahColor,
                chartColors.kosongColor,
              ],
              borderWidth: 0,
              pointStyle: "rectRounded",
            },
          ],
        },
        datainggris: {
          datasets: [
            {
              labels: ["Benar", "Salah", "Kosong"],
              data: [85, 5, 10],
              backgroundColor: [
                chartColors.benarColor,
                chartColors.salahColor,
                chartColors.kosongColor,
              ],
              borderWidth: 0,
              pointStyle: "rectRounded",
            },
          ],
        },
      },
      persentase: [
        {
          name: "Benar",
          color: chartColors.benarColor,
          percentage: 80,
        },
        {
          name: "Salah",
          color: chartColors.salahColor,
          percentage: 20,
        },
        {
          name: "Kosong",
          color: chartColors.kosongColor,
          percentage: 0,
        },
      ],
      persentaseIndo: [
        {
          name: "Benar",
          color: chartColors.benarColor,
          percentage: 100,
        },
        {
          name: "Salah",
          color: chartColors.salahColor,
          percentage: 0,
        },
        {
          name: "Kosong",
          color: chartColors.kosongColor,
          percentage: 0,
        },
      ],
      persentaseInggris: [
        {
          name: "Benar",
          color: chartColors.benarColor,
          percentage: 80,
        },
        {
          name: "Salah",
          color: chartColors.salahColor,
          percentage: 5,
        },
        {
          name: "Kosong",
          color: chartColors.kosongColor,
          percentage: 10,
        },
      ],
      list: [
        {
          id: 1,
          title: "Kedinasan",
          subtes: 3,
          time: "1 Januari 2023, 13:00 WIB s/d 7 Januari 2023, 18:00 WIB",
        },
        {
          id: 2,
          title: "UTBK SNBT",
          subtes: 7,
          time: "1 Januari 2023, 13:00 WIB s/d 7 Januari 2023, 18:00 WIB",
        },
      ],
      detail: {},
    };
  },
  computed: {
    paketDetail() {
      return this.$store.state.paketTryout.detail;
    },
    chartjsDataBar() {
      return this.$store.state.grafik.chartjsDataBar;
    },
  },
  components: {
    BOverlay,
    BAlert,
    BLink,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
    ChartjsComponentBarChart,
    ChartjsComponentDoughnutChart,
    StatisticCardHorizontal,
    vSelect,
  },
  watch: {
    jadwal(val) {
      if (val) {
        this.getPersentase();
        this.lihatStatistik();
      }
    },
  },
  methods: {
    dataDoughnut(jumlah_benar, jumlah_salah, jumlah_kosong) {
      let data = {
        datasets: [
          {
            labels: ["Benar", "Salah", "Kosong"],
            data: [jumlah_benar, jumlah_salah, jumlah_kosong],
            backgroundColor: [
              chartColors.benarColor,
              chartColors.salahColor,
              chartColors.kosongColor,
            ],
            borderWidth: 0,
            pointStyle: "rectRounded",
          },
        ],
      };
      return data;
    },
    async getPersentase() {
      this.loading = true;
      let params = {
        paket_id: this.paketDetail.id,
        jadwal_id: this.jadwal ? this.jadwal.id : null,
      };
      try {
        this.loading = false;
        const persentase = await this.$store.dispatch(
          "grafik/persentase",
          params
        );
        this.dataPersentase = persentase.data;
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },

    lihatStatistik() {
      this.loading = true;
      let params = {
        paket_id: this.paketDetail.id,
        jadwal_id: this.jadwal ? this.jadwal.id : null,
      };
      this.$store
        .dispatch("grafik/rekap", params)
        .then((res) => {
          this.loading = false;
          let chartjsDataBar = {
            data: res.data,
            options: {
              elements: {
                rectangle: {
                  borderWidth: 2,
                  borderSkipped: "bottom",
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              responsiveAnimationDuration: 500,
              legend: {
                display: true,
              },
              tooltips: {
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: chartColors.tooltipShadow,
                backgroundColor: $themeColors.light,
                titleFontColor: $themeColors.dark,
                bodyFontColor: $themeColors.dark,
              },
              scales: {
                xAxes: [
                  {
                    display: true,
                    gridLines: {
                      display: true,
                      color: chartColors.grid_line_color,
                      zeroLineColor: chartColors.grid_line_color,
                    },
                    scaleLabel: {
                      display: false,
                    },
                    ticks: {
                      fontColor: chartColors.labelColor,
                    },
                  },
                ],
                yAxes: [
                  {
                    display: true,
                    gridLines: {
                      color: chartColors.grid_line_color,
                      zeroLineColor: chartColors.grid_line_color,
                    },
                    ticks: {
                      stepSize: 20,
                      min: 0,
                      max: 100,
                      fontColor: chartColors.labelColor,
                    },
                  },
                ],
              },
            },
          };
          this.$store.commit("grafik/SET_CHART_DATA_BAR", chartjsDataBar);
        })
        .catch((err) => {
          this.loading = false;
          this.displayError(err);
          return false;
        });
    },

    getJadwal() {
      let params = {
        paket_id: this.paketDetail.id,
        user_id: this.user.id,
        is_complete: 1,
      };
      this.$store
        .dispatch("paketTryout/jadwalSaya", params)
        .then((res) => {
          this.listJadwal = res.data.data;
        })
        .catch((err) => {
          this.displayError(err);
          return false;
        });
    },
  },
  created() {
    this.getJadwal();
  },
};
</script>

<style></style>
